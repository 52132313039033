import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailMask from 'text-mask-addons/dist/emailMask';
import { fromEvent, interval, Subscription } from 'rxjs';
import { AuthService } from '../../../security/service/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng';
import { LocalizationService } from '../../../localization/service/localization.service';
import { SubSink } from '../../../localization/utils';
import { RegistrationComponent } from '../../../registration/view/registration/registration.component';
import { Constants } from '../../../constants/Constants';
import { ConfigService } from '@ngx-config/core';
declare var $: any;

@Component({
    selector: 'app-login-registration',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild('passwordInput') passwordInput: ElementRef<HTMLInputElement>;
    public formProcessing = false;
    public loginForm: FormGroup;
    public passwordRecoveryBlockSwitched = false;
    public alreadySetInterval = false;
    public passwordShown = false;
    public aboutMugBlockSwitched = false;
    public showMugFooter = true;
    public showMugFooterInReg = false;
    public emailMask = emailMask;
    public authByPhone = true;
    public readonly subSink: SubSink = new SubSink();
    public wrongLoginPasswordMessage: string;
    public accountLockedMessage: string;
    public badRequest: string;
    public activeTab = 'login';
    public regStep = 1;
    public regCapchaChecked = false;
    public recCapchaChecked = false;
    public isEnabledEmail = true;
    private sub: Subscription;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService,
        private readonly router: Router,
        private messageService: MessageService,
        private readonly localizationService: LocalizationService,
        private readonly configService: ConfigService,
    ) {}

    ngOnInit(): void {
        const { auth } = this.configService.getSettings('enableEmail');
        this.isEnabledEmail = auth;

        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            this.wrongLoginPasswordMessage = this.localizationService.getMessage('login.wrongLoginPasswordMessage');
            this.accountLockedMessage = this.localizationService.getMessage('login.accountLockedMessage');
            this.badRequest = this.localizationService.getMessage('badRequest');
        });
        this.loginForm = this.formBuilder.group({
            phoneLogin: ['', Validators.required],
            emailLogin: [
                '',
                [Validators.required, Validators.pattern(Constants.email)],
            ],
            password: ['', Validators.required],
        });
        fromEvent(document, 'show.bs.modal').subscribe(($event) => {
            this.passwordRecoveryBlockSwitched = false;
            this.aboutMugBlockSwitched = false;
            this.loginForm.reset();
            this.messageService.clear();
            this.activeTab = 'login';
            this.showMugFooter = true;
            this.regStep = 1;
            this.showMugFooterInReg = false;
            this.regCapchaChecked = false;
            this.recCapchaChecked = false;
            if (this.sub) {
                this.clearMessageService();
            }
        });

        // autofill workaround
        this.loginForm.valueChanges.subscribe(({ password }) => {
            if (!password && !this.loginForm.controls['password'].touched) {
                this.loginForm.controls['password'].reset('', { emitEvent: false });
            }
        });
    }

    public onLoginFormSubmit(): void {
        const login = this.authByPhone ? 'phoneLogin' : 'emailLogin';
        this.loginForm.controls[login].markAsDirty();
        this.loginForm.controls['password'].markAsDirty();

        if (this.passwordInput.nativeElement.value && !this.loginForm.controls['password'].value) {
            this.loginForm.controls['password'].setValue(this.passwordInput.nativeElement.value);
        }
        if (!this.isValid()) { return; }

        this.formProcessing = true;
        const value = this.loginForm.value;
        if (this.sub) {
            this.clearMessageService();
        }

        const showExtraMessageAfterLogin = this.configService.getSettings('showExtraMessageAfterLogin');

        const loginObserver = {
            next: () => {
                $('#authRegModal').modal('hide');
                const routeAfterLogin = sessionStorage.getItem('routeAfterLogin');
                this.router.navigate(routeAfterLogin ? [routeAfterLogin] : ['/ride-history'])
                    .then(() => {
                        this.formProcessing = false;
                        if (showExtraMessageAfterLogin) {
                            this.authService.dataStream.next(3);
                        }
                    });
            },
            error: (errorData) => {
                // if (errorData.error.status === 'WITHOUT_CONSENT') {
                //     sessionStorage.setItem('sessionId', errorData.error.sessionId);
                //     $('#authRegModal').modal('hide');
                //     $('#userAgreementModal').modal('show');
                // }
                let errorMessage;
                this.messageService.clear();
                if (errorData.error.status === 'WRONG_LOGIN_PASSWORD') {
                    if (errorData.error.accountLockPeriod) {
                        errorMessage = this.accountLockedMessage.replace(
                            'accountLockPeriod',
                            RegistrationComponent.getStringTimerValue(errorData.error.accountLockRemainingPeriod),
                        );
                        this.startAccountLockTimer(errorData.error.accountLockRemainingPeriod, this.accountLockedMessage);
                    } else {
                        errorMessage = this.wrongLoginPasswordMessage;
                    }
                } else {
                    errorMessage = this.badRequest;
                }
                this.messageService.add({
                    severity: 'error',
                    summary: '',
                    detail: errorMessage,
                    sticky: true,
                });
                this.formProcessing = false;
            },
        };
        if (this.authByPhone) {
            this.subSink.sink = this.authService
                .loginByMsisdn(value.phoneLogin, value.password)
                .subscribe(loginObserver);
        } else {
            this.subSink.sink = this.authService
                .loginByEmail(value.emailLogin, value.password)
                .subscribe(loginObserver);
        }
    }

    public showPassword(): void {
        this.passwordShown = !this.passwordShown;
    }

    isValid() {
        return this.authByPhone
            ? this.loginForm.controls['phoneLogin'].valid && this.loginForm.controls['password'].valid
            : this.loginForm.controls['emailLogin'].valid && this.loginForm.controls['password'].valid;
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    switchTo(tab: string) {
        if (this.activeTab === tab) {
            return;
        }
        this.activeTab = tab;
        this.showMugFooter = tab === 'login';
        this.regCapchaChecked = false;
        this.showMugFooterInReg = tab !== 'login' && this.regStep === 3;
        this.clearMessageService();
    }

    private startAccountLockTimer(accountLockPeriod: number, message: string) {
        let currentTimerValue = accountLockPeriod;
        if (!this.alreadySetInterval) {
            this.alreadySetInterval = true;
            this.sub = interval(1000).subscribe((val) => {
                currentTimerValue = currentTimerValue - 1000;
                if (currentTimerValue < 0) {
                    this.clearMessageService();
                    return;
                }
                const accountLockedMessage = message.replace(
                    'accountLockPeriod',
                    RegistrationComponent.getStringTimerValue(currentTimerValue),
                );
                this.messageService.clear();
                this.messageService.add({
                    severity: 'error',
                    summary: '',
                    detail: accountLockedMessage,
                    sticky: true,
                });
                $('.ui-messages-close').click(() => {
                    this.clearMessageService();
                    return;
                });
            });
        }
    }

    clearMessageService() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.alreadySetInterval = false;
        this.messageService.clear();
    }

    toRecoveryBlock() {
        this.clearMessageService();
        this.passwordRecoveryBlockSwitched = true;
    }
}
