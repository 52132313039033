<div
    class="modal fade"
    id="authRegModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCenterTitle"
    data-backdrop="static"
    aria-hidden="true"
>
    <div class="modal-dialog login-reg" role="document">
        <div class="modal-content">
            <div *ngIf="!passwordRecoveryBlockSwitched && aboutMugBlockSwitched">
                <app-about-mug
                    (onBack)="passwordRecoveryBlockSwitched = false; aboutMugBlockSwitched = false"
                ></app-about-mug>
            </div>
            <div *ngIf="passwordRecoveryBlockSwitched">
                <app-password-recovery [capchaChecked]="recCapchaChecked"></app-password-recovery>
            </div>
            <div class="modal-body d-none" [class.d-block]="!passwordRecoveryBlockSwitched && !aboutMugBlockSwitched">
                <div class="d-flex justify-content-center">
                    <ul class="nav login-reg-tabs">
                        <li class="nav-item">
                            <a
                                class="nav-link active"
                                data-toggle="tab"
                                href="#login"
                                (click)="switchTo('login')"
                                [ngClass]="{ active: activeTab === 'login' }"
                                >{{ 'login.loginTabTitle' | translate }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                data-toggle="tab"
                                href="#reg"
                                (click)="switchTo('reg')"
                                [ngClass]="{ active: activeTab === 'reg' }"
                            >
                                {{ 'login.registrationTabTitle' | translate }}</a
                            >
                        </li>
                    </ul>
                </div>
                <div class="tab-content d-flex">
                    <div
                        class="tab-pane show active w-100 p-md-3 pt-4 pt-sm-1"
                        id="login"
                        [ngClass]="{ active: activeTab === 'login' }"
                    >
                        <nav>
                            <div class="nav nav-tabs d-flex justify-content-between" id="nav-tab" role="tablist">
                                <a id="phone-tab-link"
                                   class="nav-item nav-link active sml"
                                   data-toggle="tab"
                                   href="#phoneInput"
                                   (click)="authByPhone = true">
                                    {{ 'login.loginByPhoneNumberLinkTitle' | translate }}
                                </a>

                                <a *ngIf="isEnabledEmail"
                                   id="email-tab-link"
                                   class="nav-item nav-link sml"
                                   data-toggle="tab"
                                   href="#emailInput"
                                   (click)="authByPhone = false">
                                    {{ 'login.loginByEmailNumberLinkTitle' | translate }}
                                </a>
                            </div>
                        </nav>
                        <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
                            <div class="form-group tab-content pt-1">
                                <div id="phoneInput" class="tab-pane fade show active">
                                    <input
                                        id="phone"
                                        class="form-control"
                                        type="tel"
                                        formControlName="phoneLogin"
                                        mask="000 000 00 00"
                                        prefix="+7 "
                                        [showMaskTyped]="true"
                                        placeHolderCharacter=" "
                                    />
                                    <span
                                        class="validation-error"
                                        *ngIf="
                                            !loginForm.controls['phoneLogin'].valid &&
                                            loginForm.controls['phoneLogin'].dirty
                                        "
                                    >
                                        {{ 'fieldIsRequred' | translate }}
                                    </span>
                                </div>
                                <div id="emailInput" class="tab-pane fade">
                                    <input
                                        id="email"
                                        class="form-control"
                                        formControlName="emailLogin"
                                        [textMask]="{ mask: emailMask }"
                                        placeholder="example@domain.com"
                                    />
                                    <span
                                        class="validation-error"
                                        *ngIf="
                                            !loginForm.controls['emailLogin'].valid &&
                                            loginForm.controls['emailLogin'].dirty
                                        "
                                    >
                                        {{ 'fieldIsRequred' | translate }}
                                    </span>
                                </div>
                                <div class="pt-4 pt-sm-2 nav nav-tabs d-flex justify-content-between">
                                    <div class="nav-item nav-link active sml">{{ 'login.password' | translate }}</div>
                                    <a class="nav-item nav-link sml" (click)="toRecoveryBlock()">{{
                                        'login.forgotPassword' | translate
                                    }}</a>
                                </div>
                                <div class="input-group input-group-seamless-append">
                                    <input
                                        #passwordInput
                                        [attr.type]="passwordShown ? 'text' : 'password'"
                                        class="form-control pwd"
                                        autocomplete="new-password"
                                        formControlName="password"
                                    />
                                    <div class="input-group-append">
                                        <button class="show-password-img-button" type="button" (click)="showPassword()">
                                            <img
                                                src="assets/layout/images/show-password-eye-inactive.svg"
                                                *ngIf="!passwordShown"
                                            />
                                            <img
                                                src="assets/layout/images/show-password-eye-active.svg"
                                                *ngIf="passwordShown"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <span
                                    class="validation-error"
                                    *ngIf="
                                        !loginForm.controls['password'].valid && loginForm.controls['password'].dirty
                                    "
                                >
                                    {{ 'fieldIsRequred' | translate }}
                                </span>
                                <div class="w-100 pt-md-4 pt-sm-2">
                                    <p-messages id="login-message"></p-messages>
                                </div>
                                <div class="pt-5 pt-sm-2 d-flex justify-content-between">
                                    <button
                                        #closeModal
                                        type="button"
                                        class="btn button-white"
                                        data-toggle="modal"
                                        data-dismiss="modal"
                                    >
                                        {{ 'cancelButtonLabel' | translate }}
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn button-red"
                                        data-toggle="modal"
                                        [disabled]="formProcessing"
                                    >
                                        {{ 'loginButtonLabel' | translate }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane w-100 p-md-3 pt-4" id="reg" [ngClass]="{ active: activeTab === 'reg' }">
                        <app-registration
                            [step]="regStep"
                            [capchaChecked]="regCapchaChecked"
                            (changeStep)="regStep = $event"
                            (onStep3)="showMugFooterInReg = true"
                        ></app-registration>
                    </div>
                </div>
            </div>
            <div
                class="d-block modal-footer login-reg"
                *ngIf="showMugFooter && !aboutMugBlockSwitched && !passwordRecoveryBlockSwitched"
            >
                <div class="d-flex justify-content-center align-items-center">
                    <div class="flex-column p-1 p-md-3">
                        <div class="text-center">
                            {{ 'login.footer1' | translate }}
                        </div>
                        <div style="text-align: center;">
                            {{ 'login.footer2' | translate }}
<!--                            <a class="mug-link" (click)="aboutMugBlockSwitched = true">-->
                                {{ 'login.footer3' | translate }}
<!--                            </a> {{ 'login.footer4' | translate }}-->
                          {{ 'login.footer4' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-block modal-footer login-reg"
                *ngIf="showMugFooterInReg && !aboutMugBlockSwitched && !passwordRecoveryBlockSwitched"
            >
                <div class="d-flex justify-content-center align-items-center">
                    <div class="flex-column p-1 p-md-3">
                        <div style="text-align: center;">
                            {{ 'reg.footer1' | translate }}
                        </div>
                        <div style="text-align: center;">
                            {{ 'reg.footer2' | translate }}
<!--                            <a class="mug-link" (click)="aboutMugBlockSwitched = true">-->
                                {{ 'login.footer3' | translate }}
<!--                            </a> {{ 'login.footer4' | translate }}-->
                            {{ 'login.footer4' | translate }}
                            {{ 'reg.footer3' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
